export function isEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
}

export function grabPhoneNumber(phone_number) {
    const re = /\d+/g;
    if(isNullOrEmpty(phone_number)) {
        return phone_number.match(re).join("")
    } else {
        return phone_number
    }
}

export function isNullOrEmpty(value) {
    if (value) {
      return false;
    }
    return true;
}
