import axios from "axios";

// export const BASE_URL = 'http://localhost:8000/api/';
export const BASE_URL = 'https://api.speedylawyers.org/api/';

export const authAxios = axios.create({
  baseURL: BASE_URL,
  // timeout: 1000,
  headers: { "Content-Type": "application/json" },
});

export const axiosConfig = {
  headers: {
    "Content-Type": "application/json",
    Authorization: null,
  },
};
