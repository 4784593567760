import React from 'react';
import { createRoot } from 'react-dom/client';

import { ThemeProvider } from "@emotion/react";

import { Provider } from "react-redux";
import { store } from "./redux/store";


import App from './App';
import theme from "./theme";
import './index.css';


const root = createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
);
