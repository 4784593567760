import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { useMediaQuery, useTheme, Box, Paper, Stack, Typography, Button, Container, Grid2, CardMedia, Divider, TextField, Link } from "@mui/material";
import { alpha, createTheme, ThemeProvider } from '@mui/material/styles';

// Redux
import { submitForm } from "../redux/slices/form-slice";

// Components
import LandingHeader from "../components/Header";
import MuiPhoneNumber from "mui-phone-number";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";



// Services
import { isEmail, isNullOrEmpty, grabPhoneNumber } from "../services/services";

const scales = require("../assets/scales.jpeg");
const logo_2 = require("../assets/logo_2.png");

// const background_vid = require("../assets/nash_sky_bridge.mp4");
const background_webp = require("../assets/nash_sky_bridge_4.webp");




// Constants
const alphaBlack = alpha('#121212', .75);
const alphaWhite60 = alpha('#ffffff', .4);
const alphaBlack45 = alpha('#121212', .6);
const contactFormBGBlack = alpha('#121212', .45);



const CRIMINAL_LAW = "Criminal Law";
const ESTATE_TRUST = "Estate And Trust Planning";
const CORPORATE_LAW = "Corporate Law";
const PERSONAL_INJURY = "Personal Injury";

const practice_list = {
  [CRIMINAL_LAW]: {
    title: CRIMINAL_LAW,
    intro: "We handle all types of crimes including, but not limited to:",
    items: [
      "DUI/DWI Crimes",
      "Drug Offenses",
      "Violent Crimes",
      "Theft and Property Crimes",
      "White-Collar Crimes",
      "Sex Crimes",
    ]
  },
  [ESTATE_TRUST]: {
    title: ESTATE_TRUST,
    intro: "Some of the most common services we offer:",
    items: [
      "Drafting Wills & Trusts",
      "Asset Protection Planning",
      "Tax Strategy",
      "Probate & Estate Admin",
      "Charitable Giving",
      "Succession Planning",
    ]
  },
  [CORPORATE_LAW]: {
    title: CORPORATE_LAW,
    intro: "We specialize in:",
    items: [
      "Entity Formation & Structuing",
      "Mergers & Acquisitions",
      "Corporate Governance",
      "Commercial Contracts",
      "Regulatory Compliance",
      "Intellectual Property Protection",
      "Dispute Resolution",
      "Digital Assets"
    ]
  },
  [PERSONAL_INJURY]: {
    title: PERSONAL_INJURY,
    intro: "We can help with:",
    items: [
      "Car Accidents",
      "Slip and Fall Cases",
      "Medical Malpractice",
      "Product Liability",
      "Workplace Injuries",
      "Wrongful Death",
      "Insurance Disputes",
    ]
  },
}

export default function Landing() {
  return (
    <>
      {/* <CardMedia  src={`${background_vid}`} component="video" autoPlay loop muted controls={false} sx={{ */}
      <CardMedia  src={`${background_webp}`} component="img" autoPlay loop sx={{
        pointerEvents: "none",
        position: "fixed",
        top: "50%",
        left: "50%",
        minHeight: "100%",
        minWidth: "100%",
        width: "auto",
        height: "auto",
        transform: "translateX(-50%) translateY(-50%);",
        zIndex: -1
      }}/>
      <Box >
        <LandingHeader />
        <Box mt={{ xs: 10, sm: 10, md: 20, lg: 20, xl: 20}}>
          <ContactForm />
        </Box>
        <Box>
          <OurPractices />
        </Box>
        <Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
}

const comp_desc_1 = "We know finding the right lawyer can feel overwhelming, especially during stressful times. That’s why we’re here to help, at no cost to you. We’ll guide you through the process, pairing you with the most qualified lawyer from our network—each vetted and proven to deliver strong results.";
const comp_desc_2 = "With our experience and trusted relationships, you can feel confident knowing you’re in good hands. We’ve helped many people just like you get the representation they need. Simply fill out the form or call us now, and we’ll quickly match you with a compassionate, experienced lawyer dedicated to fighting for the best outcome in your case.";

const light = {
  palette: {
    mode: "light",
  },
};

const ContactForm = () => {
  const dispatch = useDispatch();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [info, setInfo] = useState(null);
  const [formReceived, setFormReceived] = useState(false);
  


  useEffect(() => {
    if (isNullOrEmpty(firstName) || isNullOrEmpty(lastName) || isNullOrEmpty(email) || !isEmail(email) || isNullOrEmpty(phoneNumber) || isNullOrEmpty(info)) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }


  }, [firstName, lastName, email, phoneNumber, info]);

  const onPhoneNumberChanged = (phoneNumber, country) => {
    setPhoneNumber(grabPhoneNumber(phoneNumber));
  };

  const submitClick = async () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      info: info
    };
    try {
      setSubmitDisabled(true);
      await dispatch(submitForm(payload)).unwrap();
      setFormReceived(true);
    } catch (exception) {
      // setFeedback({ passed: false, comp: <FeedBack passed={false} message="Sign-in failed. Please try again" mt={1} /> });
      setSubmitDisabled(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: contactFormBGBlack, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "100%", backdropFilter: "blur(3px)", py: 2 }}>
      <Container maxWidth="xl" sx={{ py: 2 }}>
        <Stack direction="row">
          <Grid2 container spacing={20}>
            <Grid2 size={{xs: 12, md: 6}}>
              <Stack direction="row">
              <Stack direction="column">
                <Typography fontSize="4rem" color="white" mb={3} sx={{ textShadow: '-2px 2px 0 #fff', textAlign: "center" }}>Fast, Free Legal Placement. Delivers Results</Typography>
                <Typography variant="h5" fontSize="1.25rem" color="white" mb={5}>{comp_desc_1}</Typography>
                <Typography variant="h5" fontSize="1.25rem" color="white">{comp_desc_2}</Typography>
              </Stack>
              </Stack>
            </Grid2>
            <Grid2 size={{xs: 12, md: 6}}  >
            <Typography fontSize="4rem" color="white" sx={{ textShadow: '-2px 2px 0 #fff', textAlign: "center" }}>We’ll Find You the Best Lawyer, Fast and Free</Typography>
            <Divider flexItem sx={{ borderBottomWidth: 'medium', my: 1, borderColor: "white", mb: 3 }}/>
            <ThemeProvider theme={createTheme(light)}>
              <Paper sx={{ borderRadius: "20px", }}>
                {!formReceived ? 
                  <Stack spacing={3} py={2} px={1}>
                    <Stack direction="row" spacing={1}>
                      <TextField name="firstName" fullWidth label={<Typography fontSize="1rem" sx={{ textShadow: '-1px 1px 0 black' }}>First Name *</Typography>} variant="filled" onChange={(e) => setFirstName(e.target.value)} />
                      <TextField name="lastName" fullWidth label={<Typography fontSize="1rem" sx={{ textShadow: '-1px 1px 0 black' }}>Last Name *</Typography>} variant="filled" onChange={(e) => setLastName(e.target.value)} />
                    </Stack>
                    <TextField name="email" fullWidth label={<Typography fontSize="1rem" sx={{ textShadow: '-1px 1px 0 black' }}>Email *</Typography>} variant="filled" onChange={(e) => setEmail(e.target.value)} />
                    <MuiPhoneNumber defaultCountry="us" name="phone" fullWidth variant="filled" disableDropdown label={<Typography fontSize="1rem" sx={{ textShadow: '-1px 1px 0 black' }} >Phone *</Typography>} onChange={onPhoneNumberChanged} />
                    {/* <TextField name="phone" fullWidth label={<Typography fontSize="1rem" value={displayNumber} sx={{ textShadow: '-1px 1px 0 black' }}>Phone*</Typography>} variant="filled"  /> */}
                    <TextField name="desc" fullWidth variant="filled" multiline rows={5} label={<Typography fontSize="1rem" sx={{ textShadow: '-1px 1px 0 black' }}>Tell us your recent charges to find your lawyer *</Typography>} onChange={(e) => setInfo(e.target.value)} />
                    <Button variant="contained" size="large" color="warning" disabled={submitDisabled} sx={{ fontSize: "1.25rem", borderRadius: "20px", alignSelf: "center", width: "300px", height: "70px", boxShadow: '-2px 2px 0 black' }} onClick={submitClick}>Submit</Button>  
                  </Stack> :
                  <Stack spacing={3} py={2} px={1} alignItems="center">
                    <Typography variant="h4" textAlign="center" sx={{ textShadow: '-1px 1px 0 black' }}>Thank You for Reaching Out Your Legal Help Is on the Way </Typography>
                    <Typography variant="h5"textAlign="center" sx={{ textShadow: '-1px 1px 0 black' }}>Our team will connect you with the perfect lawyer to fit your needs shortly.</Typography>
                    <Typography variant="h5"textAlign="center" sx={{ textShadow: '-1px 1px 0 black' }}>Please check your Email for additional information and our contact number! </Typography>
                  </Stack>
                }
              </Paper>
            </ThemeProvider>
            </Grid2>
          </Grid2>
        </Stack>
      </Container>
    </Box>
  );
}


const FieldOfWorkCards = ({ practice, bgColor }) => {
  return (
    <Paper elevation={22} sx={{ backgroundColor: bgColor, m: 1 }}>
      <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" width={{ md: "300px", xl: "320px"}} height="400px" px={4} pt={1} pb={4}>
        <Typography fontSize="2.4rem" fontStyle="italic" mb={2} textAlign="center" sx={{ textShadow: '-1px 1px 0 #fff' }}>{practice.title}</Typography>
        {/* NOTE: For subtext menu item, unused. <Typography fontSize="1.2rem" mb={3}>{practice.intro}</Typography> */}
          {practice.items.map((item, idx) => (
            <Typography key={idx} fontSize="1.25rem" mb={1}>• {item}</Typography>
          ))}
      </Stack>
    </Paper>
  );
}

const OurPractices = () => {
  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.up("md"));
  
  return (
    <Box sx={{ backgroundImage: `url(${scales})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "100%", opacity: .95, py: 10 }}>
      {bp ? 
        <Container maxWidth="xl">
          <Stack direction="column">
            <Typography variant="h1" alignSelf="center" sx={{ textShadow: '-2px 2px 0 #121212', }}>
              Our Main Practicies
            </Typography>
            <Divider flexItem sx={{ borderBottomWidth: 'medium', my: 1, borderColor: "black" }}/>
            <Box display="flex" flexDirection={bp ? "row" : "column"} justifyContent="space-between">
              <Box mt={{md: 3 }}><FieldOfWorkCards practice={practice_list[CRIMINAL_LAW]} bgColor={alphaBlack} /></Box>
              <Box mt={0}><FieldOfWorkCards practice={practice_list[PERSONAL_INJURY]} /></Box>
              <Box mt={{md: 3 }}><FieldOfWorkCards practice={practice_list[CORPORATE_LAW]} bgColor={alphaBlack} /></Box>
              <Box mt={0}><FieldOfWorkCards practice={practice_list[ESTATE_TRUST]} /></Box>
            </Box>
          </Stack>
        </Container> : 
        <Stack spacing={2}>
          {/* TODO: Insert Carousel here */}
          <Typography variant="h2" fontSize="3rem" pl={1} sx={{ textShadow: '-2px 2px 0 #121212', }}>
            Our Main Practicies
          </Typography>
          <Carousel infiniteLoop interval={3000} showThumbs={false} showStatus={false} animationHandler="slide" showIndicators={true} showArrows={false} autoPlay >
              <FieldOfWorkCards practice={practice_list[CRIMINAL_LAW]} bgColor={alphaBlack} />
              <FieldOfWorkCards practice={practice_list[PERSONAL_INJURY]} />
              <FieldOfWorkCards practice={practice_list[CORPORATE_LAW]} bgColor={alphaBlack} />
              <FieldOfWorkCards practice={practice_list[ESTATE_TRUST]} />
          </Carousel>
        </Stack>
      }
    </Box>
  );
}




const Footer = () => {
  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.up("md"));
  
  return (
    <Box sx={{ backgroundColor: alphaBlack45, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "100%", height: "100%", backdropFilter: "blur(6px)", pt: 17, pb: 32, mt: 3, }}>
      <Container maxWidth="xl">
        <Box display="flex" flexDirection={bp ? "row" : "column"} justifyContent="space-evenly" height="600px">
          <Stack direction="column" spacing={2} alignItems="center">
            <Stack direction="column" spacing={6} alignItems="center">
              <Typography variant="h4" color="white"textAlign="center" sx={{ textShadow: '-1px 1px 0 #fff' }}>
                Still gambling with your future? One wrong move could cost you everything.
              </Typography>
              <Typography variant="h4" color="white"textAlign="center" sx={{ textShadow: '-1px 1px 0 #fff' }}>
                Don’t risk it! We’ll match you with a battle-tested lawyer from our exclusive network—fast, reliable, and completely free.
              </Typography>
              <Typography variant="h4" color="white"textAlign="center" sx={{ textShadow: '-1px 1px 0 #fff' }}>
                No hidden fees, no delays—secure your defense NOW before it’s too late!
              </Typography>
              <Typography variant="h2" color="white"textAlign="center" sx={{ textShadow: '-1px 1px 0 #fff' }}>
                Contact Us • Open Every Day
              </Typography>
            </Stack>
            <Link href="tel:2255776482" sx={{ color: "white", textShadow: '-1px 1px 0 #fff', textUnderlineOffset: "12px" }}>
              <Typography variant="h3" color="white" alignSelf="center" mb={5}>
                (225)-577-6482
              </Typography>
            </Link>
          </Stack>
          <CardMedia component="img" image={logo_2} sx={{ width: "auto" }} />
        </Box>
      </Container>
    </Box>
  );
}
