import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Landing from "./ui/Landing";


export default function Router() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />}></Route>
        <Route path="*" element={<Landing />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
