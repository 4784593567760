import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "underscore";

import { BASE_URL, axiosConfig, } from "../../services/client";

const coreState = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  info: ""
};

let initialState = {
  ...coreState,
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {},
  extraReducers(builder) {
    submitFormCases(builder);
  },
});

// Action creators are generated for each case reducer function
// export const { logOut, setToken } = userSlice.actions;

export default formSlice.reducer;

export const submitForm = createAsyncThunk("users/verifyUser", async (payload, { rejectWithValue } ) => {
  try {

    const response = await axios.post(BASE_URL + "contact/submit", payload, axiosConfig);

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const submitFormCases = (builder) =>
  builder
    .addCase(submitForm.fulfilled, (state, action) => {
    })
    .addCase(submitForm.rejected, (state) => {
    });
