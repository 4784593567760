import { configureStore } from '@reduxjs/toolkit'

import formReducer from "./slices/form-slice";


export const store = configureStore({
    reducer: {
        contact_form: formReducer,
    },
  })
