import {  createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
    palette: {
      mode: 'dark',

    },
    typography:{
      fontSize: 12,
      fontFamily: "Century Schoolbook",
    //   fontFamily: "Georgia",
    //   fontFamily: "Baskerville",
      // fontFamily: "Roboto",
    },
    spacing: 8,
    components: {
      // Name of the component
      MuiButtonBase: {
        defaultProps: {
          // The props to apply
          // disableRipple: true,
        },
        styleOverrides: {
          root: {
            '&:hover': {
              // boxShadow: '0px 4px 35px -5px #4082f5;',
            },
          }
        }
      },
    },
  });
  
  
  theme = responsiveFontSizes(theme)
  
  export default theme;